import Vue from 'vue'
import Router from 'vue-router'
import Cookie from "js-cookie";

Vue.use(Router);

const router = new Router({
    routes: [//一级路由
        {
            path: '/login/web',
            name: 'login',
            component: () => import('../pages/login.vue')
        },
        {
            path:'/list',
            name:'list',
            component: () => import('../pages/list.vue'),
            meta: {isAuth: true}
        },
        {
            path: '/upload',
            name: 'upload',
            component: () => import('../pages/upload.vue'),
            meta: {isAuth: true}
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (to.meta.isAuth) {
        if (Cookie.get("Token")) {
            next();
        } else {
            next('/login/web');
        }
    } else {
        next();
    }
})

export default router
